<template>
  <div>
    <div class="mail posi-fixed">
      <div class="mail-heard width-100 bg-color row-end">
        <div class="height-100 pointer flex-center" @click="hideClick">
          <div class="mail-heard-border"></div>
        </div>
        <!-- <img src="../assets/about-6.png" alt="" /> -->
      </div>
      <div class="main-center width-100">
        <div class="title">No online operator, please leave a message</div>
        <div class="main-input-item mt-1 width-100">
          <div>Name*</div>
          <div class="width-100 item-input">
            <el-input
              v-model="query.name"
              placeholder="enter your name"
            ></el-input>
          </div>
        </div>
        <div class="main-input-item mt-1 width-100">
          <div>Phone Number / WhatsApp</div>
          <div class="width-100 item-input">
            <el-input v-model="query.phone" placeholder=""></el-input>
          </div>
        </div>
        <div class="main-input-item mt-1 width-100">
          <div>E-mail</div>
          <div class="width-100 item-input">
            <el-input
              v-model="query.email"
              placeholder="Enter your email address"
            ></el-input>
          </div>
        </div>
        <div class="main-input-item mt-1 width-100">
          <div>Your question*</div>
          <div class="width-100 item-input item-input-one">
            <el-input
              v-model="query.content"
              type="textarea"
              placeholder="Enter your message"
            ></el-input>
          </div>
        </div>
        <div
          class="bg-color message-button flex-center pointer"
          @click="messageClick"
        >
          Message
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/index";
export default {
  components: {},

  data() {
    return {
      input: "",
      query: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },
    };
  },

  created() {},

  mounted() {},

  methods: {
    messageClick() {
      if (this.query.name.trim() == "") {
        this.$message.error("Please fill in your name");
        return;
      } else if (this.query.content.trim() == "") {
        this.$message.error("Please fill in the question");
        return;
      }
      api.addMessage(this.query).then((res) => {
        if (res.code == 200) {
          Object.keys(this.query).forEach((key) => {
            this.query[key] = "";
          });
          this.$message({
            message: "Submitted successfully",
            type: "success",
          });
          this.hideClick();
        }
      });
    },
    hideClick() {
      this.$emit("hide");
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  cursor: pointer;
}
.mail::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 2px;
}
</style>
<style lang="less" scoped>
.mail {
  width: 265px;
  height: 410px;
  background: #ffffff;
  border-radius: 12px;
  left: 48px;
  bottom: 48px;
  overflow: auto;
  .mail-heard {
    height: 26px;
    padding: 0 16px;
    border-radius: 12px 12px 0 0;
    .mail-heard-border {
      width: 18px;
      height: 0px;
      opacity: 1;
      border: 1px solid #ffffff;
    }
    img {
      widows: 14px;
      height: 14px;
    }
  }
  .main-center {
    padding: 12px;
    color: #333333;
    font-size: 12px;
    .title {
      font-weight: bold;
    }
    .main-input-item {
      .item-input {
        height: 31px;
        margin-top: 6px;
        /deep/.el-input {
          height: 31px;
        }
        /deep/.el-input__inner {
          height: 31px;
          line-height: 31px;
        }
        /deep/ .el-input__inner:focus {
          border-color: #ff652b;
        }
      }
      .item-input-one {
        height: auto;
      }
    }
    .message-button {
      margin-top: 16px;
      width: 80px;
      height: 37px;
      border-radius: 5px;
      color: #ffffff;
    }
  }
}
.mail::-webkit-scrollbar {
  display: none;
}
</style>
